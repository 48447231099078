@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.articleCard {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-light;
    border-radius: $border-radius-lg;
    background-color: $white;
    transition:
        background-color $transition,
        border-color $transition,
        color $transition;
    a:not(:global(.tag)) {
        @extend %link-mask;
        color: inherit;
        transition: none;
    }

    &--dark {
        color: $grey-dark;
        .articleCardInfo {
            color: $grey-dark;
        }

        &:hover {
            color: $anthracite-grey;

            .articleCardInfo {
                color: $grey-dark;
            }
        }
    }

    &--landscape {
        @media ($mdUp) {
            flex-flow: row nowrap;

            .articleCardImageContainer {
                flex: 0 0 50%;
                margin: -1px;

                .articleCardImage {
                    height: calc(100%);
                    border-top-right-radius: 0;
                    border-bottom-left-radius: $border-radius-lg;
                }
            }

            .articleCardContent {
                flex: 0 0 50%;
            }
        }
    }

    &--portrait {
    }

    // HOVERs
    &:hover {
        border-color: transparent;
        background-color: $white;
    }
}
.articleCardImageContainer {
    position: relative;
    margin: -1px;
}
.articleCardContent {
    padding: 0.75rem 1.125rem;

    @media ($smUp) {
        padding: 1.25rem 1.125rem 0.75rem;
    }

    @media ($mdUp) {
        padding: 1.5rem 1.125rem 1.25rem;
    }
}
.articleCardImage {
    position: relative;
    margin-bottom: 0;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    overflow: hidden;
    &::before {
        content: '';
        display: block;
    }

    // VARIANTs
    &--4x3::before {
        padding-top: percentage(divide(3, 4));
    }
    &--16x9::before {
        padding-top: percentage(divide(9, 16));
    }
}
.articleCardHeading {
    margin-bottom: 1.25rem;
    &:first-child:last-child {
        margin-top: -0.25rem;
    }
}
.articleCardInfo {
    padding-top: 1rem;
    border-top: 1px solid $grey-light;
    color: $grey-dark;
    font-size: 0.875rem;
    line-height: divide(24, 18);
    transition: color $transition;
    :global(.icon) {
        font-size: 1rem;
    }
}
.articleCardPerex {
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.articleCardTags {
    margin-top: 1rem;
    @media ($smUp) {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        z-index: 1;
    }
    padding: 0 1.125rem;

    > *:not(:last-child) {
        display: inline-block;
        margin-right: 0.5rem;
        margin-bottom: 0.25rem;
    }
}
